import HeroBanner from "../../components/HeroBanner"
import Layout from "../../components/Layout"
import React from "react"
import BookingOnline from "../../components/BookingOnline"
import { graphql } from "gatsby"
import useWindowSize from "../../../hooks/useWindowSize"
import Seo from "../../components/Seo"
import CommonLink from "../../components/CommonLink"
import { PortableText } from "@portabletext/react"

const SingleOffer = ({ data }) => {
  const { width } = useWindowSize()
  const { thumbnail, bookLink, features, _rawDescription, title, rate } =
    data.sanitySpecialOffers
  return (
    <>
      <Seo
        title={`${title} - Special Offer`}
        description={
          "This page special offers description page.To show user's available offer at seasons and for special category people's."
        }
      />
      <Layout>
        <div className="slider__overflow">
          <HeroBanner
            bannerImg={thumbnail?.asset?.gatsbyImageData}
            pageClass={"single__offer"}
          />
          <div className="action__section">
            <div className="container">
              <BookingOnline rate={rate} />
            </div>
          </div>
          <div className="special__section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="special_flx">
                    <div className="special_tlk">
                      <div className="special_blk">
                        <div className="special_site_left_text">
                          <h3>{title}</h3>
                          <a href="#" className="mb-2">
                            {features}
                          </a>
                        </div>
                        {width > 767 && (
                          <div className="special_site_right_text">
                            <p>{rate}</p>
                            <CommonLink
                              link={bookLink?.path}
                              type={bookLink?.type}
                            >
                              {bookLink?.label}
                            </CommonLink>
                          </div>
                        )}
                      </div>
                      <div className="special_all_content">
                        <PortableText value={_rawDescription} />
                      </div>
                    </div>
                    {width < 768 && (
                      <div className="special_site_right_text">
                        <p>{rate}</p>
                        <CommonLink link={bookLink?.path} type={bookLink?.type}>
                          {bookLink?.label}
                        </CommonLink>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default SingleOffer
export const SingleOffers = graphql`
  query SingleOffersQuery($id: String) {
    sanitySpecialOffers(id: { eq: $id }) {
      title
      rate
      features
      _rawDescription
      bookLink {
        label
        path
        type
      }
      thumbnail {
        asset {
          gatsbyImageData(fit: FILLMAX, formats: WEBP)
        }
      }
    }
  }
`
